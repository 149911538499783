import { useMutation, useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import firebase from 'firebase/app';
import 'firebase/auth';
import moment from 'moment';
import fetch from 'node-fetch';
import React, { useContext, useEffect, useRef, useState } from 'react';
import Loader from 'react-loader-spinner';
import { EXPRESS_SERVER_URL, WEBSITE_URL } from '../../config';
import { ApolloContext } from '../../context/Apollo';
import { EMAIL_TEMPLATES } from '../../utils/constants';
import { toast, ToastContainer } from 'material-react-toastify';

// graphql query to update the totalCredit and remainingCredit into the company table
const UPDATE_CREDIT = gql`
  mutation updateCompany($id: uuid, $totalCredit: numeric, $remainingCredit: numeric) {
    update_company(where: { id: { _eq: $id } }, _set: { totalCredit: $totalCredit, remainingCredit: $remainingCredit }) {
      affected_rows
      returning {
        id
        name
        totalCredit
        remainingCredit
        subscription_type
      }
    }
  }
`;

// graphql query to create payment history and update the total credits for the company
const CREATE_PAYMENT_HISTORY = gql`
  mutation CreatePayment($credits: numeric, $date: String, $type: smallint, $company_id: uuid, $candidate_id: String, $credit_amount: numeric) {
    insert_payment_history(objects: { credits: $credits, date: $date, type: $type, company_id: $company_id, candidate_id: $candidate_id, credit_amount: $credit_amount }) {
      returning {
        id
        credits
        date
        type
        company_id
        company {
          email
        }
      }
    }
  }
`;

const UPDATE_COMPANY_REQUEST = gql`
  mutation updateCompanyRequest($candidate_id: String, $company_id: uuid, $status: String, $credit_amount: numeric, $date: date) {
    update_company_request(
      where: { candidate_id: { _eq: $candidate_id }, company_id: { _eq: $company_id } }
      _set: { status: $status, credit_amount: $credit_amount, date: $date, unlocked_date: $date }
    ) {
      affected_rows
      returning {
        candidate_id
        company_id
        status
        credit_amount
        date
        candidate {
          email
          name
          recruiter {
            email
            name
            company {
              name
            }
          }
          jobs {
            job {
              title
            }
          }
        }
        company {
          name
        }
      }
    }
  }
`;

const INSERT_COMPANY_REQUEST = gql`
  mutation updateCompanyRequest($candidate_id: String, $company_id: uuid, $status: String, $credit_amount: numeric, $date: date) {
    insert_company_request(objects: { candidate_id: $candidate_id, company_id: $company_id, status: $status, credit_amount: $credit_amount, date: $date, unlocked_date: $date }) {
      returning {
        candidate_id
        company_id
        status
        credit_amount
        date
        unlocked_date
        candidate {
          email
          name
          recruiter {
            email
            name
            company {
              name
            }
          }
          jobs {
            job {
              title
            }
          }
        }
        company {
          name
        }
      }
    }
  }
`;

const GET_COMPANY_ID = gql`
  query getCompanyID($admin_id: String) {
    company(where: { adminID: { _eq: $admin_id } }) {
      id
      remainingCredit
      subscription_type
    }
  }
`;

const SETTINGS = gql`
  query getSettings {
    settings {
      placement_fee_percentage
      guarantee_period
      recruiter_share
      cherrypicker_share
    }
  }
`;

export default function ApprovalConfirmModal({ state,  excludeApprovedCandidate, changeStatus= false, candidateId }) {
  const [modal, setModal] = state;
  const [, setFadeDelay] = useState(false);
  const modalRef = useRef();

  const [emailData, setEmailData] = useState();
  const [flag, setFlag] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  // set current date
  const [date] = useState(new Date());

  // calling mutation when submit the modal having totalCredit
  const [updateCompanyCredit, { data: submissionResponse, loading }] = useMutation(UPDATE_CREDIT);

  // mutaion to update the status in the company_request table
  const [updateCandidateRequest, {data: updateCandidateResponse, loading: updateCandidateResponseLoading}] = useMutation(UPDATE_COMPANY_REQUEST);

  // mutaion to update the status in the company_request table
  const [insertCandidateRequest, { data: insertCandidateResponse, loading: insertCandidateResponseLoading }] = useMutation(INSERT_COMPANY_REQUEST);

  // mutation to create new payment history and update the credits in company
  const [createPaymentHistory, { data: paymentResponseData }] = useMutation(CREATE_PAYMENT_HISTORY);

  const { apolloClient } = useContext(ApolloContext);

  useEffect(() => {
    if (modal.open) {
      if (modal.data.candidate && modal.data.candidate.recruiter && modal.data.candidate.recruiter.notification_settings) {
        if (modal.data.candidate.recruiter.notification_settings.notification_ids.includes(1)) {
          setFlag(true);
        }
      } else {
        setFlag(false);
      }

      setFadeDelay(true);
      setEmailData(modal.data);
    } else {
      setTimeout(() => {
        setFadeDelay(false);
      }, 300);
    }
  }, [modal]);

  // update credit handler (form submit), called when click on the yes from the modal
  const updateCredit = async (evt) => {
    evt.preventDefault();

    // Check if enough credits are available
    // const response = await apolloClient.query({
    //   query: GET_COMPANY_ID,
    //   variables: { admin_id: firebase.auth().currentUser && firebase.auth().currentUser.uid },
    // });

    // if (response.data.company[0].remainingCredit <= 0) {
    //   setModal({ ...modal, open: false, reset: true });

    //   return;
    // }

    // If paid account, then don't check available credit
    // if (response.data.company[0].subscription_type === 'PAID') {
    //   //
    // } else {
    //   if (response.data.company[0].remainingCredit <= 0) {
    //     setModal({ ...modal, open: false, reset: true });

    //     return;
    //   }
    // }

    // Check if company request exist. If yes, then update, else insert
    const companyRequestExist = await apolloClient.query({
      query: gql`
        query getCompanyRequest($companyId: uuid, $candidateId: String) {
          company_request(where: { company_id: { _eq: $companyId }, candidate_id: { _eq: $candidateId } }) {
            company_id
            candidate_id
          }
        }
      `,
      variables: { candidateId: modal.data.candidateID, companyId: modal.data.subscriber.id },
    });

    if (companyRequestExist.data.company_request.length) {
      updateCandidateRequest({
        variables: {
          candidate_id: modal.data.candidateID,
          company_id: modal.data.subscriber.id,
          status: 'approved',
          credit_amount: 1,
          date: moment(new Date(date)).format('YYYY-MM-DD'),
        },
      });
    } else {
      insertCandidateRequest({
        variables: {
          candidate_id: modal.data.candidateID,
          company_id: modal.data.subscriber.id,
          status: 'approved',
          credit_amount: 1,
          date: moment(new Date(date)).format('YYYY-MM-DD'),
        },
      });
    }
    toast.success('Candidate unlocked!, go to "Unlocked Candidate" page to find unlocked candidates');

    // TODO: In the middle of implementing this it was brought to my attention that this would unlock all jobs for the user for this subscriber. This wouldn't make sense
    // Check if job candidate request exists. If so, set it to approved
    // const jobRequestExists = await apolloClient.query({
    //   query: gql`
    //     query getJobCandidateRequest($companyId: uuid, $candidateId: String) {
    //       job_candidate(where: { candidate_id: { _eq: $candidateId }, status: {_eq: 'interested'}, company_requests: {company_id: { _eq: $companyId }, } }) {
    //         job_id
    //         candidate_id
    //         status
    //       }
    //     }
    //   `,
    //   variables: { candidateId: modal.data.candidateID, companyId: modal.data.subscriber.id },
    // });

    // if (jobRequestExists.data.job_candidate.length) {
    //   jobRequestExists.data.job_candidate.map(function () {
    //     updateJobCandidateRequest({
    //       variables: {
    //         candidate_id: modal.data.candidateID,
    //         job_id: modal.data.subscriber.id,
    //         status: 'approved',
    //       },
    //     });
    //   });
    // }


    // If paid account, then don't deduct available credit
    // if (response.data.company[0].subscription_type === 'PAID') {
    //   setModal({ open: false });
    // } else {
    //   updateCompanyCredit({
    //     variables: {
    //       id: modal.data.subscriber.id,
    //       remainingCredit: parseInt(modal.data.subscriber.remainingCredit) - 1,
    //       totalCredit: parseInt(modal.data.subscriber.totalCredit) - 1,
    //     },
    //   });
    // }

    // removing the record in payment history table
    // const dateString = moment(date).format('YYYY-MM-DD');
    // const paymentHistory = {
    //   credits: 1,
    //   date: dateString,
    //   type: 2,
    //   company_id: modal.data.subscriber.id,
    //   candidate_id: modal.data.candidateID,
    //   credit_amount: 1,
    // };
    // createPaymentHistory({
    //   variables: paymentHistory,
    // });
  };

  useEffect(() => {
    if (updateCandidateResponse) {
      changeStatus && excludeApprovedCandidate && excludeApprovedCandidate(candidateId);
      let email = '';
      let candidate_name = '';
      let recruiter_name = '';
      let recruiter_company = '';
      let candidate_email = '';
      if (
        updateCandidateResponse.update_company_request.returning[0] &&
        updateCandidateResponse.update_company_request.returning[0].candidate &&
        updateCandidateResponse.update_company_request.returning[0].candidate.recruiter &&
        updateCandidateResponse.update_company_request.returning[0].candidate.recruiter.email
      ) {
        email = updateCandidateResponse.update_company_request.returning[0].candidate.recruiter.email;
        candidate_name = updateCandidateResponse.update_company_request.returning[0].candidate.name;
        recruiter_name = updateCandidateResponse.update_company_request.returning[0].candidate.recruiter.name;
        recruiter_company = updateCandidateResponse.update_company_request.returning[0].candidate?.recruiter?.company?.name;
        candidate_email = updateCandidateResponse.update_company_request.returning[0].candidate.email
      } else {
        email = 'cjtufano@getcherrypicker.com';
      }
      let company_name = 'NA';
      if (updateCandidateResponse?.update_company_request?.returning[0]?.company?.name) {
        company_name = updateCandidateResponse?.update_company_request?.returning[0]?.company?.name;
      }
      let job_title = 'N/A';
      if (updateCandidateResponse?.update_company_request?.returning[0]?.job?.job?.title) {
        job_title = updateCandidateResponse?.update_company_request?.returning[0]?.job?.job?.title;
      }
      fetch(`${EXPRESS_SERVER_URL}/send-email`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          recipient: email,
          // subject: 'Subscriber interested in your candidate profile', //added in sendGrid
          template_id: EMAIL_TEMPLATES.TO_RECRUITER_WHEN_COMPANY_UNLOCK_CANDIDATE_PROFILE, //candidate unlock
          attributes: {
            subscriber_name: company_name,
            recruiter_company,
            job_title,   // not used in sendgrid
            recruiter_name,
            candidate_name,
            company_name,
            website_url: WEBSITE_URL,
          },
        }),
      });

      fetch(`${EXPRESS_SERVER_URL}/send-email`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          recipient: candidate_email,
          // subject: 'Great news, a company has unlocked your Cherrypicker profile!', //added in sendGrid
          template_id: EMAIL_TEMPLATES.TO_CANDIDATE_WHEN_PROFILE_UNLOCKED_BY_SUBSCRIBER,
          attributes: {
            recruiter_company,
            candidate_name,
          },
        }),
      });
    }
  }, [updateCandidateResponse]);

  useEffect(() => {
    // return
    if (insertCandidateResponse) {
      changeStatus && excludeApprovedCandidate(candidateId);
      let email = '';
      let candidate_name = '';
      let recruiter_name = '';
      let recruiter_company = '';
      let candidate_email = '';
      if (
        insertCandidateResponse.insert_company_request.returning[0] &&
        insertCandidateResponse.insert_company_request.returning[0].candidate &&
        insertCandidateResponse.insert_company_request.returning[0].candidate.recruiter &&
        insertCandidateResponse.insert_company_request.returning[0].candidate.recruiter.email
      ) {
        email = insertCandidateResponse.insert_company_request.returning[0].candidate.recruiter.email;
        candidate_name = insertCandidateResponse.insert_company_request.returning[0].candidate.name;
        recruiter_name = insertCandidateResponse.insert_company_request.returning[0].candidate.recruiter.name;
        recruiter_company = insertCandidateResponse.insert_company_request.returning[0].candidate?.recruiter?.company?.name
        candidate_email =  insertCandidateResponse.insert_company_request.returning[0].candidate.email;
      } else {
        email = 'cjtufano@getcherrypicker.com';
      }
      let company_name = 'NA';
      if (insertCandidateResponse?.insert_company_request?.returning[0]?.company?.name) {
        company_name = insertCandidateResponse?.insert_company_request?.returning[0]?.company?.name;
      }
      let job_title = 'N/A';
      if (insertCandidateResponse?.insert_company_request?.returning[0]?.job?.job?.title) {
        job_title = insertCandidateResponse?.insert_company_request?.returning[0]?.job?.job?.title;
      }
      fetch(`${EXPRESS_SERVER_URL}/send-email`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          recipient: email,
          // subject: 'Subscriber interested in your candidate profile', //added in sendGrid
          template_id: EMAIL_TEMPLATES.TO_RECRUITER_WHEN_COMPANY_UNLOCK_CANDIDATE_PROFILE, //candidate unlock
          attributes: {
            subscriber_name: company_name,
            recruiter_company,
            job_title,   // not used in sendgrid
            recruiter_name,
            candidate_name,
            company_name,
            website_url: WEBSITE_URL,
          },
        }),
      });

      fetch(`${EXPRESS_SERVER_URL}/send-email`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          recipient: candidate_email,
          // subject: 'Great news, a company has unlocked your Cherrypicker profile!', //added in sendGrid
          template_id: EMAIL_TEMPLATES.TO_CANDIDATE_WHEN_PROFILE_UNLOCKED_BY_SUBSCRIBER,
          attributes: {
            recruiter_company,
            candidate_name,
          },
        }),
      });
    }
  }, [insertCandidateResponse]);

  /*
  useEffect(() => {
    if (paymentResponseData) {
      if (flag) {
        fetch(`${EXPRESS_SERVER_URL}/send-email`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            recipient: emailData?.candidate?.recruiter?.email || 'cjtufano@getcherrypicker.com',
            template_id: EMAIL_TEMPLATES.TO_RECRUITER_WHEN_COMPANY_UNLOCK_CANDIDATE_PROFILE,
            attributes: {
              company_name: emailData?.subscriber?.name,
              recruiter_name: emailData?.candidate?.recruiter?.name,
              candidate_name: emailData?.candidate?.name,
              website_url: WEBSITE_URL,
            },
          })
        });
      }
    }
  }, [paymentResponseData]);
  */

  // when updateCredit handler submits successfully then closing the modal
  useEffect(() => {
    if (submissionResponse) {
      setModal({ open: false });
    }
  }, [submissionResponse]);

  const handleCheckBoxChange = (e) => {
    let checkedValue = !isChecked
    setIsChecked(checkedValue)
  }

  const handleAgreementClick = (url) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  }

  const { data: feeConfigSettingsData } = useQuery(SETTINGS);
  const { data: companyData } =  useQuery(GET_COMPANY_ID, {
    variables: { admin_id: firebase.auth().currentUser && firebase.auth().currentUser.uid }
});
const subscriptionType = companyData?.company?.[0]?.subscription_type;
  const guaranteePeriodDays = feeConfigSettingsData?.settings?.[0]?.guarantee_period;
  const placement_fee_percentage = feeConfigSettingsData?.settings?.[0]?.placement_fee_percentage;

  const isFree = subscriptionType === 'FREsE' ? true : false;
  return (
    <React.Fragment>
      <form onSubmit={updateCredit}>
        <div
          onClick={() => setModal({ ...modal, open: true, reset: true })}
          className={`approval-confirm-modal-js fixed inset-0 ${modal.open ? 'flex' : 'hidden'} items-center justify-center font-main`}
          style={{ backgroundColor: 'rgba(34, 46, 66, 0.9)', zIndex: 20000000000000000, minWidth: '20rem' }}
        >
          <div ref={modalRef} onClick={(e) => e.stopPropagation()} className="bg-white px-xl py-md rounded shadow flex flex-col">
            <div className={`text-darkblue font-medium ${ isFree ? 'border-b' : '' } flex justify-between pb-sm`} style={{ maxWidth: 'calc(100vw - 30px)' }}>
              {!isFree ? <div>Confirm Candidate Unlock</div> :
              <div></div>}
              <div onClick={() => setModal({ open: false })} className="text-darkgray hover:text-darkblue cursor-pointer">
                x
              </div>
            </div>
            {isFree ? <>
              <p>Are you sure, you want to unlock this candidate’s profile?</p>
              <div className="flex flex-1 justify-center items-end gap-2" style={{ marginTop: 10, marginBottom: 10 }}>
                <button
                  className="rounded bg-red ml-md font-medium  text-sm text-white"
                  style={{ width: '25%', height: 40, fontSize: 14 }}
                  type='submit'
                >
                  {(loading || updateCandidateResponseLoading || insertCandidateResponseLoading) ? <Loader type="TailSpin" className="flex justify-center" color="#FFFFFF" height={20} width={20} /> : <span className='text-white'>Confirm</span>}
                </button>
                <button
                  className="rounded border mr-sm font-medium text-sm bg-lightgray text-darkgray"
                  style={{ width: '25%', height: 40, fontSize: 14 }}
                  type='button'
                  onClick={() => setModal({ ...modal, open: false, reset: true })}
                >
                  Cancel
                </button>
              </div>
            </> :
            <>
            <div className="text-darkblue text-sm py-lg" style={{ width: '50vw' }}>
              By unlocking this candidate profile, you are agreeing to
              <a style={{ color: 'blue' }} className='text-textblue hover:text-textblue cursor-pointer' onClick={() => handleAgreementClick('https://www.getcherrypicker.com/feeagreement')}> our placement fee agreement </a>
              <br />
              <div className='flex flex-col mb-0 pb-0 gap-0 justify-start align-middle items-start mt-3'>
                <p className='mb-1 font-semibold'>Terms Highlights</p>
                <p className='mb-0'>- {placement_fee_percentage || 'N/A'}% placement fee based on salary hired</p>
                <p className='mb-0'>- {guaranteePeriodDays || 'N/A'} day guarantee period from candidate start date</p>
                <p className='mb-0'>- Cherrypicker candidate ownership period: 12 months from unlock date</p>
              </div>
            </div>
            <div className='flex gap-1 m-auto'>
              <input value={!isChecked} onChange={handleCheckBoxChange} type="checkbox" className="checked:bg-blue-500 font-medium" />
              <span>I agree to the Cherrypicker Placement Fee terms.</span>
            </div>
            <div className="flex justify-center pt-lg">
              <button
                className={`mr-sm rounded ${isChecked ? 'bg-red cursor-pointer' : 'bg-lightgray text-darkgray cursor-not-allowed'} ml-md font-medium flex justify-center items-center text-sm text-white`}
                style={{ width: 137, height: 55, fontSize: 14, }}
                disabled={!isChecked}
              >
                {(loading || updateCandidateResponseLoading || insertCandidateResponseLoading) ? <Loader type="TailSpin" className="flex justify-center" color="#FFFFFF" height={20} width={20} /> : <span className={`${isChecked ? 'text-white' : 'text-darkgray'}`}>Confirm</span>}
              </button>
              <button
                onClick={() => setModal({ ...modal, open: false, reset: true })}
                type="button"
                className="rounded border font-medium text-sm bg-lightgray text-darkgray"
                style={{ width: 137, height: 55, fontSize: 14 }}
              >
                Cancel
              </button>

            </div>
        </>}
          </div>
        </div>
      </form>
      <ToastContainer style={{ width: 'fit-content' }} theme="dark" position="bottom-center" autoClose={6000} hideProgressBar newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
    </React.Fragment>
  );
}
